<template>
  <div class="container">
    <img
      class="top"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/aito/aito_top.jpg"
    />

    <div class="form">
      <van-field
        v-model="form.phone_number"
        type="tel"
        maxlength="11"
        placeholder="请输入您的手机号码"
      />

      <van-field
        v-model="form.phone_code"
        type="number"
        center
        maxlength="6"
        autocomplete="one-time-code"
        placeholder="请输入验证码"
        class="input-code"
      >
        <template slot="right-icon">
          <div class="code" v-show="sendAuthCode" @click="getAuthCode">
            获取验证码
          </div>
          <div class="code" v-show="!sendAuthCode">
            <span>{{ auth_time }}秒</span>重新获取
          </div>
        </template>
      </van-field>
      <img
        class="submit"
        @click="onSubmit"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/aito/aito_submit.png"
      />

      <div class="checked">
        <van-checkbox
          checked-color="#ffd614"
          v-model="checked"
          icon-size="14px"
        >
          &nbsp;我已阅读并同意<span class="book" @click="dialogInfo"
            >《用户服务协议》</span
          >
        </van-checkbox>
      </div>
    </div>
    <div class="title">
        -- 活动规则 --
    </div>
    <div class="info">
      <div class="text">1.活动对象:<br>2G/3G/4G/5G手机用户。</div>
      <div class="text">
        本页面仅供客户购买权益，非用于鉴权登录等其他服务，老年及未成年人请谨慎消费，敬请知悉!为了您的权益保障，请注意核实订购规则及订单详情，不要轻信陌生人通过网络、电话、短信告知的信息，谨防网络电信诈骗。</div>
      <div class="text">2.活动内容:</div>
      <div class="text">
        订购AI兔会员，首月29.9元，后续39.9元/月，每30天可领取热门会员权益*2，到期前24小时内将自动扣费续订。例如张三在2023年3月15日10:56分订购了AI兔会员，那么在2023年3月15日10:56分~2023年4月13日10:55期间，张三可任选2款会员权益。如张三在2023年4月12日10:55前未退订服务，那么在2023年4月12日10:55~2023年4月13日10:55期间将自动扣费下一个30天计费周期的费用。</div>
      <div class="text"> 3.生失效方式:</div>
      <div class="text">
        订购立即生效，当前计费周期费用(30天)一次性扣除下一个计费周期自动续订，退订需在到期前24小时。
      </div>
      <div class="text">4.权益领取使用及有效期:</div>
      <div class="text">
        会员权益每个计费周期(30天)限领1次，逾期作废。
      </div>
      <div class="text">领取路径:登录支付宝APP,进入支付宝小程序【AI兔相机】，点击【AI兔会员】，进入后选择需要的权益领取;</div>

      <div class="text">
        (1)会员权益如腾讯爱奇艺登录对应平台即可使用;</div>

      <div class="text">(2)会员权益领取后发放至订购手机号;</div>
      <div class="text">
        5.退订方式
      </div>
      <div class="text">退订用户需在会员权益到期前24小时联系取消，在下一个计费周期生效，且取消后6个月内不能再次订购;</div>
      <div class="text">
        退订路径:打开支付宝-[我的]-[设置]-[支付设置]-[免密支付/自动扣款]，选择AI兔取消订阅微信[我的]-[服务]-[钱包]-[支付设置]-[自动续费]，选择AI兔取消订阅。</div>

      <div class="text">6.如对活动有疑问或更多需求，请咨询小程序在线客服。</div>
      <div class="text">
        【订购须知】
      </div>
      <div class="text">1、本页面仅供客户购买权益，非用于鉴权登录等其他服务，老年及未成年人请谨慎消费，敬请知悉!</div>
      <div class="text">
        2、为了您的权益保障，请注意核实订购规则及订单详情，不要轻信陌生人通过网络、电话、短信告知的信息，谨防网络电信诈骗。</div>
      
    </div>
    <v-dialog :visibility.sync="visibility" />
  </div>
</template>

<script>
import { code, codeDlipayPay } from "@/api/aito.js";
import VDialog from "./dialog/index.vue";
export default {
  data() {
    return {
      form: {
        phone_number: "",
        phone_code: "",
      },
      sendAuthCode: true,
      checked: false,
      auth_time: 0,
      state: false,
      visibility: false,
    };
  },
  components: {
    VDialog,
  },
  methods: {
    // 验证码
    async getAuthCode() {
      let filterPhone = /^1[3456789]\d{9}$/;
      const { phone_number } = this.form;
      if (!filterPhone.test(phone_number)) {
        this.$toast.fail("手机号错误");
        return;
      }
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      const res = await code({ phone_number });
      this.$toast.clear();
      if (res.code != 0) {
        this.$toast.fail(res.message);
      } else {
        // 设置倒计时秒
        this.sendAuthCode = false;
        this.auth_time = 60;
        let auth_timetimer = setInterval(() => {
          this.auth_time--;
          if (this.auth_time <= 0) {
            this.sendAuthCode = true;
            clearInterval(auth_timetimer);
          }
        }, 1000);
      }
    },
    async onSubmit() {
      let filtercode = /^\d{6}$/;
      let filterPhone = /^1[3456789]\d{9}$/;
      const { phone_number, phone_code } = this.form;
      if (!filterPhone.test(phone_number)) {
        this.$toast.fail("请输入正确的手机号码");
      }else if (!filtercode.test(phone_code)) {
        this.$toast.fail("请输入正确的验证码");
      } else if (!this.checked) {
        this.$toast.fail("请阅读并同意用户服务协议");
      } else {
        this.$toast.loading({
          duration: 0,
          forbidClick: true,
          mask: true,
          message: "加载中...",
        });
        const res = await codeDlipayPay({
          phone_number,
          phone_code,
          item_name: "AI兔会员",
        });
        this.$toast.clear();
        if (res.code != 0) {
          this.$toast({
            duration: 8000,
            message: res.message,
          });
        } else {
          location.href = `alipays://platformapi/startapp?appId=20000067&url=${encodeURIComponent(
            res.sign_str
          )}`;
        }
      }
    },
    dialogInfo() {
      this.visibility = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #fb5372;
  padding-bottom: 20px;
  .top,
  .card {
    width: 100%;
  }

  .form {
    height: 500px;
    width: 750px;
    padding-top: 33px;
    box-sizing: border-box;

    ::v-deep .van-cell {
      width: 560px;
      height: 100px;
      background-color: #ffffff;
      border-radius: 50px;
      margin: 0px auto 0;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 23px;

      &.input-code {
        margin-top: 20px;

        .code {
          width: 190px;
          height: 100px;
          text-align: center;
          line-height: 100px;
          color: #e42970;
          font-size: 28px;
          font-weight: normal;
          background-color: #ffe8ec;
        }
      }

      .van-field__body {
        height: 100%;

        input {
          height: 100%;
        }
      }

      &::after {
        border: none;
      }

      .van-field__control {
        color: #000;
        font-weight: normal;
        &::-webkit-input-placeholder {
          font-size: 34px;
          font-weight: 40;
        }
      }
    }

    .submit {
      width: 572px;
      display: block;
      margin: 50px auto 0;
    }

    .checked {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 24px;
      font-weight: 400;
      padding: 10px 0 300px;

      ::v-deep .van-checkbox {
        width: auto;
        .van-checkbox__icon .van-icon{
          border: 1px solid #fff;
        }

        &__label {
          margin-left: 4px;
          color: #fff !important;

          .book {
            color: #fdff5d;
          }
        }
      }
    }
  }
  .title {
    width: 80vw;
    height: 60px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    // background-color: #fff;
    background-image: linear-gradient(-90deg, #ffccd5 0%, #ffeef1 50%, #fff 100%),
          linear-gradient(#ffffff, #ffffff);
    text-align: center;
    line-height: 60px;
    font-size: 36px;
    font-weight: 900;
    margin: 0 auto;
    color: #e42970;
    }
  .info {
    width: 94vw;
    border-radius: 20px;
    margin: 0 auto;
    box-sizing: border-box;
    font-size: 24px;
    font-weight: 500;
    padding: 20px 40px 60px;
    line-height: 40px;
    color: #4d2f1c;
    background-image: linear-gradient(0deg, #ffccd5 0%, #ffeef1 50%, #fff 100%),
            linear-gradient(#ffffff, #ffffff);

    .text {
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 36px;
      letter-spacing: 0px;
    }
  }
}
</style>
